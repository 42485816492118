import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

export default function QuitScreen({ route, navigation }: { route: any, navigation: any }) {
    const { id, nickname } = route.params;

    const handlePress = async () => {
        try {
            const response = await fetch(`http://153.126.194.159:3000/user/exit/${id}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                navigation.replace('Result', { id });
            } else {
                console.error('Failed to exit');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.questionText}>あなたの名前は{nickname}ですか？</Text>
            <TouchableOpacity
                style={styles.quitButton}
                onPress={handlePress}
            >
                <Text style={styles.buttonText}>退出</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    questionText: {
        fontSize: 24,
        marginBottom: 20,
    },
    quitButton: {
        backgroundColor: 'red',
        width: "40%",
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 20,
    },
});
