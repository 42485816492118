import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

export default function ResultScreen({ route, navigation }: { route: any, navigation: any }) {
    const { id } = route.params;
    const [duration, setDuration] = React.useState<string>('');

    React.useEffect(() => {
        const fetchDuration = async () => {
            try {
                const response = await fetch(`http://153.126.194.159:3000/user/time/${id}`, {
                    method: 'GET',
                });
                if (response.status === 200) {
                    const data = await response.json();
                    setDuration(data.duration);
                } else {
                    console.error('Failed to fetch duration');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchDuration();
    }, [id]);

    return (
        <View style={styles.container}>
            <Text style={styles.questionText}>あなたの滞在時間は{duration}です</Text>
            <TouchableOpacity
                style={styles.quitButton}
                onPress={() => navigation.navigate('Home')}
            >
                <Text style={styles.buttonText}>退出</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    questionText: {
        fontSize: 24,
        marginBottom: 20,
    },
    quitButton: {
        backgroundColor: 'red',
        width: "40%",
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 20,
    },
});
