import React from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet } from 'react-native';


export default function EntryScreen({ navigation }: { navigation: any }) {
    const [nickname, setNickname] = React.useState('');

    const handlePress = async () => {
        try {
            const response = await fetch(`http://153.126.194.159:3000/user/entry/${encodeURIComponent(nickname)}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                navigation.replace('Confirm', { nickname });
            } else {
                // Handle error or show a message to the user
                const json = await response.json();
                console.log('Error: ', json);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.buttonText}>入場画面</Text>
            <TextInput
                style={styles.textInput}
                placeholder="ニックネームを入力"
                value={nickname}
                onChangeText={setNickname}
            />
            <TouchableOpacity
                style={styles.button}
                onPress={handlePress}
            >
                <Text style={styles.buttonText}>送信</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInput: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        width: '50%',
        marginVertical: 20,
        paddingHorizontal: 10,
    },
    button: {
        backgroundColor: 'green',
        width: "20%",
        height: 80,
        justifyContent: 'center',
        padding: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 40,
        textAlign: 'center',
    },
});
