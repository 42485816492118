import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

export default function ExitScreen({ navigation }: { navigation: any }) {
    const [users, setUsers] = React.useState<Array<[number, string]>>([]);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            // 画面がフォーカスされた時に実行される処理
            const fetchUsers = async () => {
                try {
                    const response = await fetch('http://153.126.194.159:3000/user/current', {
                        method: 'GET',
                    });
                    if (response.status === 200) {
                        const data = await response.json();
                        if (data.userInfos === null) {
                            navigation.navigate('Home');
                        } else {
                            setUsers(data.userInfos);
                        }
                    } else {
                        console.error('Failed to fetch users');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            };

            fetchUsers();
        });

        return unsubscribe; // コンポーネントのアンマウント時にリスナーを解除
    }, [navigation]);

    return (
        <View style={styles.container}>
            <View style={styles.buttonContainer}>
                {users.map(([id, nickname]) => (
                    <TouchableOpacity
                        key={id}
                        style={styles.button}
                        onPress={() => navigation.navigate('Quit', { id, nickname })}
                    >
                        <Text style={styles.buttonText}>{nickname}</Text>
                    </TouchableOpacity>
                ))}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },

    buttonContainer: {
        width: "80%",
        height: "70%",
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    button: {
        backgroundColor: 'darkgreen',
        width: "21%",
        height: "15%",
        justifyContent: 'center',
        alignItems: 'center',
        margin: "2%",
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 20,
    },
});