import { StatusBar } from 'expo-status-bar';
import "@expo/metro-runtime";
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import EntryScreen from './EntryScreen';
import ExitScreen from './ExitScreen';
import QuitScreen from './QuitScreen';
import ResultScreen from './ResultScreen';



const Stack = createStackNavigator();

function HomeScreen({ navigation }: { navigation: any }) {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={[styles.entryButton, styles.button]} onPress={() => navigation.navigate('Entry')}>
          <Text style={styles.buttonText}>入場</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.exitButton, styles.button]} onPress={() => navigation.navigate('Exit')}>
          <Text style={styles.buttonText}>退場</Text>
        </TouchableOpacity>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

function ConfirmScreen({ route }: { route: any }) {
  const { nickname } = route.params;

  return (
    <View style={styles.container}>
      <Text style={styles.buttonText}>確認画面</Text>
      <Text style={styles.confirmText}>ニックネーム: {nickname}</Text>
    </View>
  );
}



export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="Entry" component={EntryScreen} />
        <Stack.Screen name="Confirm" component={ConfirmScreen} />
        <Stack.Screen name="Exit" component={ExitScreen} />
        <Stack.Screen name="Quit" component={QuitScreen} />
        <Stack.Screen name="Result" component={ResultScreen} />

      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonContainer: {
    width: "100%",
    padding: "5%",
    flexDirection: 'row',
    justifyContent: 'center',
  },

  button: {
    width: "40%",
    height: 150,
    justifyContent: 'center',
    margin: "2%",
    padding: 10,
    borderRadius: 5,
  },
  entryButton: {
    backgroundColor: 'green',
  },
  exitButton: {
    backgroundColor: 'red',
  },
  buttonText: {
    color: '#fff',
    fontSize: 40,
    textAlign: 'center',
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    width: '80%',
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  confirmText: {
    fontSize: 30,
    marginTop: 20,
  },
});
